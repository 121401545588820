export enum GeneralStatusEnum {
    ACTIVE = "active",
    AVAILABLE = "available",
    COMPLETED = "completed",
    DELETED = "deleted",
    DRAFT = "draft",
    FAILED = "failed",
    GENERATED = "generated",
    INACTIVE = "inactive",
    IN_PROGRESS = "in_progress",
    IN_USE = "in_use",
    PICKING = "picking",
    READY_TO_PACK = "ready_to_pack",
    PACKING = "packing",
    PACKED = "packed",
    READY_TO_SHIP = "ready_to_ship",
    PARTIAL_COMPLETED = "partial_completed",
    PENDING = "pending",
    PENDING_TAG = "pending_tag",
    SHIPPED = "shipped",
    UNAVAILABLE = "unavailable",
    VOID = "void",
    ONHOLD = "on_hold",
}

export const optionalStatus = [GeneralStatusEnum.PARTIAL_COMPLETED]
export const errorStatus = [GeneralStatusEnum.DELETED, GeneralStatusEnum.INACTIVE, GeneralStatusEnum.UNAVAILABLE, GeneralStatusEnum.VOID]

export const GeneralStatusLabel: { [key: string]: string } = {
    [GeneralStatusEnum.ACTIVE]: "Active",
    [GeneralStatusEnum.AVAILABLE]: "Available",
    [GeneralStatusEnum.COMPLETED]: "Completed",
    [GeneralStatusEnum.DELETED]: "Deleted",
    [GeneralStatusEnum.DRAFT]: "Draft",
    [GeneralStatusEnum.FAILED]: "Failed",
    [GeneralStatusEnum.GENERATED]: "Generated",
    [GeneralStatusEnum.INACTIVE]: "Inactive",
    [GeneralStatusEnum.IN_PROGRESS]: "In Progress",
    [GeneralStatusEnum.IN_USE]: "In Use",
    [GeneralStatusEnum.PICKING]: "Picking",
    [GeneralStatusEnum.READY_TO_PACK]: "Ready to Pack",
    [GeneralStatusEnum.PACKING]: "Packing",
    [GeneralStatusEnum.PACKED]: "Packed",
    [GeneralStatusEnum.READY_TO_SHIP]: "Ready to Ship",
    [GeneralStatusEnum.PARTIAL_COMPLETED]: "Partially Completed",
    [GeneralStatusEnum.PENDING]: "Pending",
    [GeneralStatusEnum.PENDING_TAG]: "Pending Tag",
    [GeneralStatusEnum.SHIPPED]: "Shipped",
    [GeneralStatusEnum.UNAVAILABLE]: "Unavailable",
    [GeneralStatusEnum.VOID]: "Void",
    [GeneralStatusEnum.ONHOLD]: "On Hold",
}

export const GeneralStatusOptions = [
    { value: GeneralStatusEnum.ACTIVE, label: GeneralStatusEnum.ACTIVE },
    { value: GeneralStatusEnum.AVAILABLE, label: GeneralStatusEnum.AVAILABLE },
    { value: GeneralStatusEnum.COMPLETED, label: GeneralStatusEnum.COMPLETED },
    { value: GeneralStatusEnum.DELETED, label: GeneralStatusEnum.DELETED },
    { value: GeneralStatusEnum.DRAFT, label: GeneralStatusEnum.DRAFT },
    { value: GeneralStatusEnum.FAILED, label: GeneralStatusEnum.FAILED },
    { value: GeneralStatusEnum.GENERATED, label: GeneralStatusEnum.GENERATED },
    { value: GeneralStatusEnum.INACTIVE, label: GeneralStatusEnum.INACTIVE },
    { value: GeneralStatusEnum.IN_PROGRESS, label: GeneralStatusEnum.IN_PROGRESS },
    { value: GeneralStatusEnum.IN_USE, label: GeneralStatusEnum.IN_USE },
    { value: GeneralStatusEnum.PICKING, label: GeneralStatusEnum.PICKING },
    { value: GeneralStatusEnum.READY_TO_PACK, label: GeneralStatusEnum.READY_TO_PACK },
    { value: GeneralStatusEnum.PACKING, label: GeneralStatusEnum.PACKING },
    { value: GeneralStatusEnum.PACKED, label: GeneralStatusEnum.PACKED },
    { value: GeneralStatusEnum.READY_TO_SHIP, label: GeneralStatusEnum.READY_TO_SHIP },
    { value: GeneralStatusEnum.PARTIAL_COMPLETED, label: GeneralStatusEnum.PARTIAL_COMPLETED },
    { value: GeneralStatusEnum.PENDING, label: GeneralStatusEnum.PENDING },
    { value: GeneralStatusEnum.PENDING_TAG, label: GeneralStatusEnum.PENDING_TAG },
    { value: GeneralStatusEnum.SHIPPED, label: GeneralStatusEnum.SHIPPED },
    { value: GeneralStatusEnum.UNAVAILABLE, label: GeneralStatusEnum.UNAVAILABLE },
    { value: GeneralStatusEnum.VOID, label: GeneralStatusEnum.VOID },
    { value: GeneralStatusEnum.ONHOLD, label: GeneralStatusEnum.ONHOLD },
]

export const GeneralStatusSelectOptions = [
    { value: GeneralStatusEnum.ACTIVE, label: GeneralStatusLabel[GeneralStatusEnum.ACTIVE] },
    { value: GeneralStatusEnum.AVAILABLE, label: GeneralStatusLabel[GeneralStatusEnum.AVAILABLE] },
    { value: GeneralStatusEnum.COMPLETED, label: GeneralStatusLabel[GeneralStatusEnum.COMPLETED] },
    { value: GeneralStatusEnum.DELETED, label: GeneralStatusLabel[GeneralStatusEnum.DELETED] },
    { value: GeneralStatusEnum.DRAFT, label: GeneralStatusLabel[GeneralStatusEnum.DRAFT] },
    { value: GeneralStatusEnum.FAILED, label: GeneralStatusLabel[GeneralStatusEnum.FAILED] },
    { value: GeneralStatusEnum.GENERATED, label: GeneralStatusLabel[GeneralStatusEnum.GENERATED] },
    { value: GeneralStatusEnum.INACTIVE, label: GeneralStatusLabel[GeneralStatusEnum.INACTIVE] },
    { value: GeneralStatusEnum.IN_PROGRESS, label: GeneralStatusLabel[GeneralStatusEnum.IN_PROGRESS] },
    { value: GeneralStatusEnum.IN_USE, label: GeneralStatusLabel[GeneralStatusEnum.IN_USE] },
    { value: GeneralStatusEnum.PICKING, label: GeneralStatusLabel[GeneralStatusEnum.PICKING] },
    { value: GeneralStatusEnum.READY_TO_PACK, label: GeneralStatusLabel[GeneralStatusEnum.READY_TO_PACK] },
    { value: GeneralStatusEnum.PACKING, label: GeneralStatusLabel[GeneralStatusEnum.PACKING] },
    { value: GeneralStatusEnum.PACKED, label: GeneralStatusLabel[GeneralStatusEnum.PACKED] },
    { value: GeneralStatusEnum.READY_TO_SHIP, label: GeneralStatusLabel[GeneralStatusEnum.READY_TO_SHIP] },
    { value: GeneralStatusEnum.PARTIAL_COMPLETED, label: GeneralStatusLabel[GeneralStatusEnum.PARTIAL_COMPLETED] },
    { value: GeneralStatusEnum.PENDING, label: GeneralStatusLabel[GeneralStatusEnum.PENDING] },
    { value: GeneralStatusEnum.PENDING_TAG, label: GeneralStatusLabel[GeneralStatusEnum.PENDING_TAG] },
    { value: GeneralStatusEnum.SHIPPED, label: GeneralStatusLabel[GeneralStatusEnum.SHIPPED] },
    { value: GeneralStatusEnum.UNAVAILABLE, label: GeneralStatusLabel[GeneralStatusEnum.UNAVAILABLE] },
    { value: GeneralStatusEnum.VOID, label: GeneralStatusLabel[GeneralStatusEnum.VOID] },
    { value: GeneralStatusEnum.ONHOLD, label: GeneralStatusLabel[GeneralStatusEnum.ONHOLD] },
]
