"use client"

import React, { ReactElement, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { Separator } from "../ui/separator"
import { StorageRow } from "@/types/StorageRow"
import { StorageBay } from "@/types/StorageBay"
import useFetchLocation from "@/api/location/useFetchLocation"
import { LocationTypeLabels } from "@/constants/LocationType"
import { GeneralStatusLabel } from "@/constants/GeneralStatus"
import { Label } from "../ui/label"
import { StorageBin } from "@/types/StorageBin"
import { cn } from "@/lib/utils"
import Loader2 from "../ui/loader2"
import useLocationLayout from "@/api/location/useLocationLayout"
import { ScrollArea } from "../ui/scroll-area"
import useFetchHistoryStorageBin from "@/api/location/useFetchHistoryStorageBin"
import { Alert, AlertDescription, AlertTitle } from "../ui/alert"
import { Info } from "lucide-react"

interface Props {
    title?: string
    locationId: string
}

interface StorageLayoutBay {
    code: string
    facing_option: string
    sequence: number
    bin?: StorageBin
}

interface StorageLayoutFacing {
    A: StorageLayoutBay[]
    B: StorageLayoutBay[]
}

interface StorageLayoutLevel {
    level: number
    facing: StorageLayoutFacing
}

interface StorageLayoutRow {
    id: number
    code: string
    bays: Pick<StorageBay, "code">[]
    levels: StorageLayoutLevel[]
}

const LocationLayout: React.FC<Props> = ({ title, locationId }): ReactElement => {
    const intl = useIntl()

    const [maxBays, setMaxBays] = useState<number>(0)
    const [historyBinIds, setHistoryBinIds] = useState<number[]>([])
    const { data: location, isFetching } = useFetchLocation({ enableHook: Boolean(locationId), id: String(locationId) })
    const storageRows = useMemo(() => location?.storage_rows ?? [], [location])
    const {
        data: { editMode, selectedStorageBinId, selectableStorageBinFn, item_id },
    } = useLocationLayout()
    const { data: historyStorageBin } = useFetchHistoryStorageBin({
        enableHook: !!item_id,
        param: {
            item_id: String(item_id),
            assigned_loc_id: String(locationId),
        },
    })
    useEffect(() => {
        if (historyStorageBin && !!item_id) {
            const binIds = historyStorageBin[0]
                .split(",")
                .map((bin: string) => bin.trim())
                .map(Number)
            setHistoryBinIds(binIds)
        }
    }, [historyStorageBin, item_id])

    useEffect(() => {
        setMaxBays(
            storageRows.reduce((acc: number, row: StorageRow) => {
                return Math.max(acc, row.storage_bays.length)
            }, 0)
        )
    }, [location, storageRows])

    const data = (storageRows as StorageRow[])
        .sort((a, b) => a.sequence - b.sequence)
        .reduce((acc: StorageLayoutRow[], row: StorageRow) => {
            const sortedBays = row.storage_bays.sort((a, b) => a.sequence - b.sequence)

            const newRow = {
                id: row.id,
                code: row.code,
                bays: sortedBays.map((bay) => ({
                    code: bay.code,
                })),
                levels: Array.from({ length: row.level }, (_, i) => {
                    const A_Bays = sortedBays.map((bay) => {
                        return {
                            code: bay.code,
                            facing_option: bay.facing_option,
                            sequence: bay.sequence,
                            bin: bay.storage_bins.find((bin) => bin.level === i + 1 && bin.facing === "A"),
                        }
                    })
                    const B_Bays = sortedBays.map((bay) => {
                        return {
                            code: bay.code,
                            facing_option: bay.facing_option,
                            sequence: bay.sequence,
                            bin: bay.storage_bins.find((bin) => bin.level === i + 1 && bin.facing === "B"),
                        }
                    })

                    return {
                        level: i + 1,
                        facing: {
                            A: A_Bays,
                            B: B_Bays,
                        },
                    }
                }),
            }

            acc.push(newRow)
            return acc
        }, [])

    return (
        <ScrollArea className="h-[calc(100vw-3rem)] w-[calc((100vw/3*2)-3rem)] ">
            <div className="flex flex-col gap-4">
                <Label className="text-xl">{title}</Label>
                <div className="grid grid-cols-4 gap-y-2">
                    <div className="flex flex-col">
                        <Label className="text-muted-foreground">{intl.formatMessage({ id: "code" })}</Label>
                        <p>{location?.code}</p>
                    </div>
                    <div className="flex flex-col">
                        <Label className="text-muted-foreground">{intl.formatMessage({ id: "branch" })}</Label>
                        <p>{location?.branch?.name}</p>
                    </div>
                    <div className="flex flex-col">
                        <Label className="text-muted-foreground">{intl.formatMessage({ id: "type" })}</Label>
                        <p>{LocationTypeLabels[location?.type as keyof typeof LocationTypeLabels]}</p>
                    </div>
                    <div className="flex flex-col">
                        <Label className="text-muted-foreground">{intl.formatMessage({ id: "status" })}</Label>
                        <p>{GeneralStatusLabel[location?.status as keyof typeof GeneralStatusLabel]}</p>
                    </div>
                    <div className="col-span-4 flex flex-col">
                        <Label className="text-muted-foreground">{intl.formatMessage({ id: "description" })}</Label>
                        <p>{location?.description}</p>
                    </div>
                </div>
                <Separator />

                {!(historyBinIds[0] === undefined) && (
                    <Alert variant="info">
                        <Info className="h-4 w-4" />
                        <div className="flex items-center justify-between gap-2">
                            <div>
                                <AlertTitle>{intl.formatMessage({ id: "putaway_selected_history_title" })}</AlertTitle>
                                <AlertDescription>{intl.formatMessage({ id: "putaway_selected_history_desc" })}</AlertDescription>
                            </div>
                        </div>
                    </Alert>
                )}
                {isFetching ? (
                    <div className="flex w-full justify-center gap-2 p-4">
                        <p>Loading...</p>
                        <Loader2 />
                    </div>
                ) : (
                    <table className="w-full border">
                        <thead>
                            <tr>
                                <td className="border p-4">{intl.formatMessage({ id: "rows" })}</td>
                                <td className="border p-4 text-center">{intl.formatMessage({ id: "level" })}</td>
                                <td className="border p-4 text-center">{intl.formatMessage({ id: "facing" })}</td>
                                <td className="border p-4 text-center" colSpan={maxBays}>
                                    {intl.formatMessage({ id: "bays" })}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row: StorageLayoutRow) => {
                                return (
                                    <React.Fragment key={`row-${row.code}`}>
                                        {row.bays.length > 0 && (
                                            <tr>
                                                <td colSpan={3} className="bg-slate-900" />
                                                {row.bays.map((bay) => (
                                                    <td key={`row-${row.code}_bay-${bay.code}`} className="border p-2 text-center">
                                                        {bay.code}
                                                    </td>
                                                ))}
                                            </tr>
                                        )}
                                        {row.levels.map((level: StorageLayoutLevel, index: number) => {
                                            return (
                                                <React.Fragment key={`row-${row.code}_level-${level.level}`}>
                                                    <tr className="border">
                                                        {index === 0 && (
                                                            <td rowSpan={row.levels.length * 2} className="border p-2">
                                                                {row.code}
                                                            </td>
                                                        )}
                                                        <td rowSpan={2} className="border p-2 text-center">
                                                            {level.level}
                                                        </td>
                                                        <td className="border p-2 text-center">A</td>
                                                        {level.facing.A.length > 0 ? (
                                                            level.facing.A.map((bay) => (
                                                                <td
                                                                    key={`row-${row.code}_level-${level.level}_A_bay-${bay.code}`}
                                                                    onClick={() => bay.bin && editMode && selectableStorageBinFn?.(bay.bin)}
                                                                    className={cn(
                                                                        "border p-2 text-center transition-colors",
                                                                        selectableStorageBinFn && editMode && bay.bin && "cursor-pointer hover:bg-green-500/50",
                                                                        !!!bay.bin
                                                                            ? "bg-slate-900"
                                                                            : bay.bin.id === selectedStorageBinId
                                                                              ? "bg-green-500"
                                                                              : historyBinIds.includes(bay.bin.id)
                                                                                ? "bg-yellow-500"
                                                                                : ""
                                                                    )}
                                                                >
                                                                    {bay.bin ? bay.bin.name : ""}
                                                                </td>
                                                            ))
                                                        ) : (
                                                            <td className="border bg-slate-900 p-2" />
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td className="border p-2 text-center">B</td>
                                                        {level.facing.B.length > 0 ? (
                                                            level.facing.B.map((bay) => (
                                                                <td
                                                                    key={`row-${row.code}_level-${level.level}_B_bay-${bay.code}`}
                                                                    onClick={() => bay.bin && editMode && selectableStorageBinFn?.(bay.bin)}
                                                                    className={cn(
                                                                        "border p-2 text-center transition-colors",
                                                                        selectableStorageBinFn && editMode && bay.bin && "cursor-pointer hover:bg-green-500/50",
                                                                        !!!bay.bin
                                                                            ? "bg-slate-900"
                                                                            : bay.bin.id === selectedStorageBinId
                                                                              ? "bg-green-500"
                                                                              : historyBinIds.includes(bay.bin.id)
                                                                                ? "bg-yellow-500"
                                                                                : ""
                                                                    )}
                                                                >
                                                                    {bay.bin ? bay.bin.name : ""}
                                                                </td>
                                                            ))
                                                        ) : (
                                                            <td className="border bg-slate-900 p-2" />
                                                        )}
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </ScrollArea>
    )
}

export default LocationLayout
